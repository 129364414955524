
import { defineComponent, reactive, ref } from 'vue';
import propertyBreadCrumb from '@/components/view/pm/bread-crumb';
import router, { propertyBaseRouter, propertySubRouter } from '@/router';
import screenSaverApi, { GetScreenSaverList } from '@/api/pm/screen-saver';
import { list, ListHeaderItem, ListActionItem } from '@/components/common/list';
import EnlargeImage from '@/components/common/enlarge-image/index.vue';
import Notice from '@/util/notice.base';

export default defineComponent({
    components: {
        propertyBreadCrumb,
        list,
        EnlargeImage
    },
    setup() {
        const breadCrumbHeaders = [{
            label: WordList.OrderDevice,
            path: `/${propertyBaseRouter}/${propertySubRouter.device}`
        }, {
            label: WordList.SetScreenSaver
        }];

        // 表格属性
        const headers: Array<ListHeaderItem> = [{
            name: 'Location',
            label: WordList.TabelPersonDeviceInHtmlLocation
        }, {
            name: 'MAC',
            label: WordList.TabelPersonDeviceInHtmlMAC
        }, {
            name: 'ScreenSaver',
            label: WordList.Screensaver,
            type: 'customize'
        }];

        // 请求接口参数
        const formData = reactive({
            row: 10,
            page: 1,
            Location: ''
        });

        const updateData = ref(0);
        // 表格数据
        const tableData = reactive<GetScreenSaverList>({
            total: 0,
            detail: [],
            rows: []
        });

        // 获取当前已设置屏保的设备列表
        function getScreenSaverApi() {
            screenSaverApi.getScreenSaverList(formData, (res) => {
                tableData.total = res.total;
                tableData.rows = res.rows;
                tableData.detail = res.detail;
                updateData.value += 1;
            });
        }
        getScreenSaverApi();

        // 搜索页数置为1
        function search() {
            formData.page = 1;
            getScreenSaverApi();
        }

        // 兼容迁移后的代码格式
        const action: ListActionItem[] = [{
            type: 'edit2',
            event(item: GetScreenSaverList['detail'][0]) {
                const infoItem = tableData.detail.find((info) => info.UUID === item.UUID);
                setScreenSaver('edit', infoItem);
            },
            class: 'el-icon-my-modify modify-icon',
            title: WordList.TabelConfigInHtmlModify
        }];

        // 新增或编辑屏保
        function setScreenSaver(type: 'add'|'edit', item?: GetScreenSaverList['detail'][0]) {
            if (type === 'edit' && item) {
                // 离线情况
                if (item.Status === '0') {
                    Notice.messageBox(
                        'alert',
                        WordList.deviceIsOffline,
                        WordList.TabelDeleteTitle,
                        undefined,
                        WordList.TabelFootSure
                    )(() => false);
                    return false;
                }
                localStorage.setItem('screenSaver', JSON.stringify(item));
                router.push(`/${propertyBaseRouter}/${propertySubRouter.setScreenSaver}?type=edit`);
            } else {
                router.push(`/${propertyBaseRouter}/${propertySubRouter.setScreenSaver}?type=add`);
            }
            return true;
        }
        console.log(12323);
        console.log(12323);

        // 放大图片
        const isShowEnlargeImage = ref(false);
        const enlargeImgUrl = ref('');
        function showLargeImg(src: string) {
            enlargeImgUrl.value = src;
            isShowEnlargeImage.value = true;
        }

        return {
            breadCrumbHeaders,
            search,
            tableData,
            getScreenSaverApi,
            formData,
            headers,
            action,
            setScreenSaver,
            updateData,
            showLargeImg,
            isShowEnlargeImage,
            enlargeImgUrl
        };
    }
});
